/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CollectionNames } from '../models/CollectionNames';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class CommonService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Count Documents By Id
     * Count documents from any collection by _id
     * @param collection
     * @param id
     * @param documentId
     * @param customerId
     * @param utilityId
     * @returns number Success
     * @throws ApiError
     */
    public countDocumentsByIdApiV2CountDocumentsGet(
        collection: CollectionNames,
        id?: string,
        documentId?: string,
        customerId?: string,
        utilityId?: string
    ): CancelablePromise<number> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v2/count_documents',
            query: {
                collection: collection,
                _id: id,
                document_id: documentId,
                customer_id: customerId,
                utility_id: utilityId,
            },
            errors: {
                400: `Bad Request Error`,
                404: `Will return 404 if no documents are in collection`,
                422: `Validation Error`,
                500: `Internal Server Error`,
            },
        });
    }
}
