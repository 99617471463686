/**
 * Enumerated route keys representing a single source of truth for the application's routes.
 */
export enum Route {
    // Account = 'Account',
    ReferenceData = 'Reference Data',
    Client = 'Client',
    ClientSettings = 'Client Settings',
    ClientUtilities = 'Client Utilities',
    Clients = 'Home',
    Invoices = 'Invoices',
    InvoicesUpload = 'Upload Invoices',
    InvoiceParser = 'Client Invoice Parser',
    InvoicesAlerts = 'Alerts',
    Settings = 'Settings',
    Utilities = 'Utilities Management',
    UtilityAdd = 'Add Utility',
    UtilityDetails = 'Utility Details',
    UtilityPromptsReporting = 'Utility Prompts Reporting',
    InvoicesErrorIntake = 'Error Intake',
    EmailPage = 'Email Page',
}
