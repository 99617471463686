/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class EcsHealthCheckService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Health Check
     * @returns any Success
     * @throws ApiError
     */
    public healthCheckTestingHealthCheckGet(): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/testing/health_check',
            errors: {
                400: `Bad Request Error`,
                404: `Not Found Error`,
                422: `Validation Error`,
                500: `Internal Server Error`,
            },
        });
    }
}
