/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Pydantic model for invoices response history records in MongoDB
 */
export type InvoicesErrorHistory = {
    /**
     * S3 path of the file in the bucket
     */
    file_key: string;
    /**
     * The message explaining the result of the response.
     */
    message: string;
    /**
     * Error message if any, explaining failure details.
     */
    error: string;
    /**
     * Color associated with the response status, e.g., 'error'.
     */
    color: string;
    /**
     * Status of the response, either 'pending' or 'resolved'.
     */
    status: InvoicesErrorHistory.status;
    /**
     * Timestamp when the document was created in the mongo db
     */
    timestamp: string;
    /**
     * Timestamp when the response was resolved, if applicable.
     */
    resolved_at?: string;
    /**
     * Subject of the email
     */
    subject: string;
    /**
     * Sender of the email
     */
    sender: string;
    /**
     * Timestamp when the email was received
     */
    date: string;
    /**
     * Name of the attachements
     */
    original_pdf_file_name: string;
    /**
     * UUId fo the file
     */
    pdf_uuid: string;
};

export namespace InvoicesErrorHistory {
    /**
     * Status of the response, either 'pending' or 'resolved'.
     */
    export enum status {
        PENDING = 'pending',
        RESOLVED = 'resolved',
    }
}
