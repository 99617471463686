import { lazy } from 'react';
import { Route } from './enums';
import {
    ClientState,
    RoutePathComponents,
    RouteConfiguration,
    RoutePathTemplates,
    SafeLocationStateTypeGuards,
    QueryParamsPrototypes,
    InvoiceParserState,
} from './types';

/**
 * Mapping of route paths to lazy-loaded route components.
 */
export const routeComponents: RoutePathComponents = {
    // [Route.Account]: lazy(() => import('../../features/invoices/pages/InvoicesPage')),
    [Route.Client]: lazy(
        () => import('../../features/clients/pages/ClientPage')
    ),
    [Route.ClientSettings]: lazy(
        () => import('../../features/clients/pages/ClientSettingsPage')
    ),
    [Route.Clients]: lazy(
        () => import('../../features/clients/pages/HomePage')
    ),
    [Route.ClientUtilities]: lazy(
        () => import('../../features/clients/pages/ClientUtilitiesPage')
    ),
    [Route.ReferenceData]: lazy(
        () => import('../../features/emissions/pages/ReferenceDataPage')
    ),
    [Route.InvoiceParser]: lazy(
        () => import('../../features/invoices/pages/InvoiceParserPage')
    ),
    [Route.InvoicesAlerts]: lazy(
        () => import('../../features/invoices/pages/InvoicesAlertsPage')
    ),
    [Route.Invoices]: lazy(
        () => import('../../features/invoices/pages/InvoicesPage')
    ),
    [Route.InvoicesUpload]: lazy(
        () => import('../../features/invoices/pages/InvoicesUploadPage')
    ),
    [Route.Settings]: lazy(
        () => import('../../features/settings/pages/SettingsPage')
    ),
    [Route.Utilities]: lazy(
        () => import('../../features/utilities/pages/UtilitiesPage')
    ),
    [Route.UtilityAdd]: lazy(
        () => import('../../features/utilities/pages/UtilityAddPage')
    ),
    [Route.UtilityDetails]: lazy(
        () => import('../../features/utilities/pages/UtilityDetailsPage')
    ),
    [Route.UtilityPromptsReporting]: lazy(
        () =>
            import('../../features/utilities/pages/UtilityPromptsReportingPage')
    ),
    [Route.InvoicesErrorIntake]: lazy(
        () => import('../../features/invoices/pages/InvoicesErrorIntakePage')
    ),
    [Route.EmailPage]: lazy(
        () => import('../../features/email/pages/EmailPage')
    ),
};

/**
 * Defines the route path templates for each route path.
 * Template string are restricted to the template string defined in types.ts.
 */
export const routePathTemplates: RoutePathTemplates = {
    // [Route.Account]: '/invoices/account/:id?isAdding={boolean}&invoiceUrl={string}&id={string}',
    [Route.Client]: '/clients/:id?name={string}',
    [Route.Clients]: '/clients/?isAdding={boolean}&invoiceUrl={string}',
    [Route.ClientSettings]: '/clients/:id/settings',
    [Route.InvoicesUpload]: '/invoices/upload',
    [Route.ClientUtilities]: '/clients/:accountId',
    [Route.InvoiceParser]: '/invoices/:id?layoutlmItemId={string}',
    [Route.InvoicesAlerts]: '/invoices/alerts',
    [Route.Invoices]: '/invoices',
    [Route.Utilities]: '/utilities',
    [Route.UtilityAdd]: '/utilities/add?invoiceUrl={string}',
    [Route.UtilityDetails]:
        '/utilities/:utilityId/prompts?utilityIdNoSql={string}&promptId={string}&category={string}&invoiceUrl={string}&isAddingPrompt={boolean}&entity={string}',
    [Route.UtilityPromptsReporting]: '/utilities/:id/prompts-reporting',
    [Route.ReferenceData]: '/reference-data',
    [Route.Settings]: '/settings',
    [Route.InvoicesErrorIntake]: '/invoices/errorIntake',
    [Route.EmailPage]: '/email',
};

/**
 * Configuration object for routes.
 */
export const routes: RouteConfiguration = {
    // [Route.Account]: {
    // 	route: Route.Account,
    // 	path: routePathTemplates[Route.Account],
    // 	component: routeComponents[Route.Account]
    // },
    [Route.Clients]: {
        route: Route.Clients,
        path: routePathTemplates[Route.Clients],
        component: routeComponents[Route.Clients],
    },
    [Route.Client]: {
        route: Route.Client,
        path: routePathTemplates[Route.Client],
        component: routeComponents[Route.Client],
    },
    [Route.ClientSettings]: {
        route: Route.ClientSettings,
        path: routePathTemplates[Route.ClientSettings],
        component: routeComponents[Route.ClientSettings],
    },
    [Route.InvoicesUpload]: {
        route: Route.InvoicesUpload,
        path: routePathTemplates[Route.InvoicesUpload],
        component: routeComponents[Route.InvoicesUpload],
    },
    [Route.ClientUtilities]: {
        route: Route.ClientUtilities,
        path: routePathTemplates[Route.ClientUtilities],
        component: routeComponents[Route.ClientUtilities],
    },
    [Route.InvoiceParser]: {
        route: Route.InvoiceParser,
        path: routePathTemplates[Route.InvoiceParser],
        component: routeComponents[Route.InvoiceParser],
    },
    [Route.InvoicesAlerts]: {
        route: Route.InvoicesAlerts,
        path: routePathTemplates[Route.InvoicesAlerts],
        component: routeComponents[Route.InvoicesAlerts],
    },
    [Route.Invoices]: {
        route: Route.Invoices,
        path: routePathTemplates[Route.Invoices],
        component: routeComponents[Route.Invoices],
    },
    [Route.Utilities]: {
        route: Route.Utilities,
        path: routePathTemplates[Route.Utilities],
        component: routeComponents[Route.Utilities],
    },
    [Route.UtilityAdd]: {
        route: Route.UtilityAdd,
        path: routePathTemplates[Route.UtilityAdd],
        component: routeComponents[Route.UtilityAdd],
    },
    [Route.UtilityDetails]: {
        route: Route.UtilityDetails,
        path: routePathTemplates[Route.UtilityDetails],
        component: routeComponents[Route.UtilityDetails],
    },
    [Route.UtilityPromptsReporting]: {
        route: Route.UtilityPromptsReporting,
        path: routePathTemplates[Route.UtilityPromptsReporting],
        component: routeComponents[Route.UtilityPromptsReporting],
    },
    [Route.ReferenceData]: {
        route: Route.ReferenceData,
        path: routePathTemplates[Route.ReferenceData],
        component: routeComponents[Route.ReferenceData],
    },
    [Route.Settings]: {
        route: Route.Settings,
        path: routePathTemplates[Route.Settings],
        component: routeComponents[Route.Settings],
    },
    [Route.InvoicesErrorIntake]: {
        route: Route.InvoicesErrorIntake,
        path: routePathTemplates[Route.InvoicesErrorIntake],
        component: routeComponents[Route.InvoicesErrorIntake],
    },
    [Route.EmailPage]: {
        route: Route.EmailPage,
        path: routePathTemplates[Route.EmailPage],
        component: routeComponents[Route.EmailPage],
    },
};

/**
 * Represents the query parameter prototypes for different routes.
 * WARNING: These need to be defined according to the respective route path templates to avoid runtime errors.
 */
export const queryParamsPrototypes: QueryParamsPrototypes = {
    // [Route.Account]: { isAdding: 'boolean', invoiceUrl: 'string', id: 'string' },
    [Route.Client]: { name: 'string' },
    [Route.ClientSettings]: {},
    [Route.ClientUtilities]: {},
    [Route.Clients]: { isAdding: 'boolean', invoiceUrl: 'string' },
    [Route.InvoiceParser]: { layoutlmItemId: 'string' },
    [Route.Invoices]: {},
    [Route.InvoicesAlerts]: {},
    [Route.InvoicesUpload]: {},
    [Route.ReferenceData]: {},
    [Route.Settings]: {},
    [Route.Utilities]: {},
    [Route.UtilityAdd]: { invoiceUrl: 'string' },
    [Route.UtilityDetails]: {
        utilityIdNoSql: 'string',
        promptId: 'string',
        invoiceUrl: 'string',
        isAddingPrompt: 'boolean',
        category: 'string',
        entity: 'string',
    },
    [Route.UtilityPromptsReporting]: { name: 'string', prompt: 'number' },
    [Route.InvoicesErrorIntake]: {},
    [Route.EmailPage]: {},
};

// ================================================ LOCATION STATE ================================================ //

/**
 * Define type guards for location (route) states here.
 */
const isDefaultState = (state: any): state is null | undefined =>
    state === null || state === undefined;

// The following is an example of a type guard for a specific route state that is not actually used in the application.
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const isClientState = (state: any): state is ClientState => {
    return typeof state?.title === 'string';
};

const isInvoiceParserState = (state: any): state is InvoiceParserState => {
    return (
        (typeof state?.highlightedText === 'string' ||
            state?.highlightedText === undefined) &&
        (typeof state?.highlightedTextStart === 'number' ||
            state?.highlightedTextStart === undefined) &&
        (typeof state?.highlightedTextEnd === 'number' ||
            state?.highlightedTextEnd === undefined) &&
        Array.isArray(state?.highlightedTextWordIds) &&
        state.highlightedTextWordIds.every(
            (item: any) => typeof item === 'number'
        ) &&
        typeof state?.highlightedTextPage === 'number'
    );
};

/**
 * Map routes to their respective type guards for required location state.
 */
export const locationStateTypeGuards: SafeLocationStateTypeGuards = {
    // [Route.Account]: isDefaultState,
    [Route.Client]: isDefaultState,
    [Route.ClientSettings]: isDefaultState,
    [Route.ClientUtilities]: isDefaultState,
    [Route.ReferenceData]: isDefaultState,
    [Route.Clients]: isDefaultState,
    [Route.InvoicesAlerts]: isDefaultState,
    [Route.Invoices]: isDefaultState,
    [Route.InvoiceParser]: isInvoiceParserState,
    [Route.InvoicesUpload]: isDefaultState,
    [Route.Settings]: isDefaultState,
    [Route.Utilities]: isDefaultState,
    [Route.UtilityAdd]: isDefaultState,
    [Route.UtilityDetails]: isDefaultState,
    [Route.UtilityPromptsReporting]: isDefaultState,
    [Route.InvoicesErrorIntake]: isDefaultState,
    [Route.EmailPage]: isDefaultState,
};
