/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { UtilityAccountSchema } from '../models/UtilityAccountSchema';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class ReportingService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * List Utility Account Or 404
     * @param skip
     * @param limit
     * @returns UtilityAccountSchema Success
     * @throws ApiError
     */
    public listUtilityAccountOr404ApiV2ReadUtilityAccountListGet(
        skip?: number,
        limit: number = 100
    ): CancelablePromise<Array<UtilityAccountSchema>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v2/read/utility-account-list',
            query: {
                skip: skip,
                limit: limit,
            },
            errors: {
                400: `Bad Request Error`,
                404: `Not Found Error`,
                422: `Validation Error`,
                500: `Internal Server Error`,
            },
        });
    }
}
