/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CollectionNames } from '../models/CollectionNames';
import type { Invoice } from '../models/Invoice';
import type { InvoiceOptional } from '../models/InvoiceOptional';
import type { InvoiceUpdate } from '../models/InvoiceUpdate';
import type { ReportAPIResponse } from '../models/ReportAPIResponse';
import type { SQLItem } from '../models/SQLItem';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class InvoiceService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Read Invoice Or 404
     * @param id
     * @param managerId
     * @param utilityId
     * @param customerId
     * @param createdDate
     * @param fileName
     * @param invoiceUrl
     * @param isValidated
     * @returns Invoice Success
     * @throws ApiError
     */
    public invoiceReadInvoiceOr404(
        id?: string,
        managerId?: string,
        utilityId?: string,
        customerId?: string,
        createdDate?: string,
        fileName?: string,
        invoiceUrl?: string,
        isValidated?: boolean
    ): CancelablePromise<Invoice> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v2/read/invoice',
            query: {
                _id: id,
                manager_id: managerId,
                utility_id: utilityId,
                customer_id: customerId,
                created_date: createdDate,
                file_name: fileName,
                invoice_url: invoiceUrl,
                is_validated: isValidated,
            },
            errors: {
                400: `Bad Request Error`,
                404: `Not Found Error`,
                422: `Validation Error`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * List Invoice Or 404
     * @param id
     * @param managerId
     * @param utilityId
     * @param customerId
     * @param createdDate
     * @param fileName
     * @param invoiceUrl
     * @param isValidated
     * @param skip
     * @param limit
     * @returns Invoice Success
     * @throws ApiError
     */
    public invoiceListInvoiceOr404(
        id?: string,
        managerId?: string,
        utilityId?: string,
        customerId?: string,
        createdDate?: string,
        fileName?: string,
        invoiceUrl?: string,
        isValidated?: boolean,
        skip?: number,
        limit?: number
    ): CancelablePromise<Array<Invoice>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v2/read/list/invoice',
            query: {
                _id: id,
                manager_id: managerId,
                utility_id: utilityId,
                customer_id: customerId,
                created_date: createdDate,
                file_name: fileName,
                invoice_url: invoiceUrl,
                is_validated: isValidated,
                skip: skip,
                limit: limit,
            },
            errors: {
                400: `Bad Request Error`,
                404: `Not Found Error`,
                422: `Validation Error`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * Read Invoice Optional Or 404
     * @param id
     * @param managerId
     * @param utilityId
     * @param customerId
     * @param createdDate
     * @param fileName
     * @param invoiceUrl
     * @param isValidated
     * @param project Query param string with comma separated field names as they appear in database
     * @returns InvoiceOptional Success
     * @throws ApiError
     */
    public invoiceReadInvoiceOptionalOr404(
        id?: string,
        managerId?: string,
        utilityId?: string,
        customerId?: string,
        createdDate?: string,
        fileName?: string,
        invoiceUrl?: string,
        isValidated?: boolean,
        project?: string
    ): CancelablePromise<InvoiceOptional> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v2/read/invoice-optional',
            query: {
                _id: id,
                manager_id: managerId,
                utility_id: utilityId,
                customer_id: customerId,
                created_date: createdDate,
                file_name: fileName,
                invoice_url: invoiceUrl,
                is_validated: isValidated,
                project: project,
            },
            errors: {
                400: `Bad Request Error`,
                404: `Not Found Error`,
                422: `Validation Error`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * List Invoice Optional Or 404
     * @param id
     * @param managerId
     * @param utilityId
     * @param customerId
     * @param createdDate
     * @param fileName
     * @param invoiceUrl
     * @param isValidated
     * @param project Query param string with comma separated field names as they appear in database
     * @param skip
     * @param limit
     * @returns InvoiceOptional Success
     * @throws ApiError
     */
    public invoiceListInvoiceOptionalOr404(
        id?: string,
        managerId?: string,
        utilityId?: string,
        customerId?: string,
        createdDate?: string,
        fileName?: string,
        invoiceUrl?: string,
        isValidated?: boolean,
        project?: string,
        skip?: number,
        limit?: number
    ): CancelablePromise<Array<InvoiceOptional>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v2/read/list/invoice-optional',
            query: {
                _id: id,
                manager_id: managerId,
                utility_id: utilityId,
                customer_id: customerId,
                created_date: createdDate,
                file_name: fileName,
                invoice_url: invoiceUrl,
                is_validated: isValidated,
                project: project,
                skip: skip,
                limit: limit,
            },
            errors: {
                400: `Bad Request Error`,
                404: `Not Found Error`,
                422: `Validation Error`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * Update Invoice Optional Or 404
     * Update one or more keys from the invoice.
     * `null` values are excluded from the update.
     *
     * Args:
     * _id PK field of invoice object.
     * - Is the auto generated primary key of the document created when document is uploaded.
     * - Is UUID V4 string
     *
     * Returns:
     * (Invoice): Updated invoice
     * @param id
     * @param requestBody
     * @returns Invoice Success
     * @throws ApiError
     */
    public invoiceUpdateInvoiceOptionalOr404(
        id: string,
        requestBody: InvoiceUpdate
    ): CancelablePromise<Invoice> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/api/v2/update/invoice/{id}',
            path: {
                id: id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request Error`,
                404: `Not Found Error`,
                422: `Validation Error`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * Update Invoice Or 404
     * Update invoice by document_id.
     * This is a random uuidV4 string generated in the pipeline
     * ans assigned when invoice is received.
     *
     * - Entire invoice is required to be passed in the request body.
     * - Updated invoice is returned in response.
     * @param requestBody
     * @returns Invoice Success
     * @throws ApiError
     */
    public invoiceUpdateInvoiceOr404(
        requestBody: Invoice
    ): CancelablePromise<Invoice> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/api/v2/update-alerts',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request Error`,
                404: `Not Found Error`,
                422: `Validation Error`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * Validate Sql Invoice Or 404
     * Endpoint to validate the invoice before submitting it to the SQL database.
     * @param id
     * @returns SQLItem Success
     * @throws ApiError
     */
    public invoiceValidateSqlInvoiceOr404(
        id: string
    ): CancelablePromise<Array<SQLItem>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v2/validate-invoice',
            query: {
                id: id,
            },
            errors: {
                400: `Bad Request Error`,
                404: `Not Found Error`,
                422: `Validation Error`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * Invoice To Sql Or 404
     * Post an invoice to the reporting API
     * saving it to the invoice table in the SQL
     * database.
     *
     * Implements:
     * - /backend/post_to_invoice_table
     * @param id
     * @returns ReportAPIResponse Success
     * @throws ApiError
     */
    public invoiceInvoiceToSqlOr404(
        id: string
    ): CancelablePromise<ReportAPIResponse> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/api/v2/complete-invoice',
            query: {
                id: id,
            },
            errors: {
                400: `Bad Request Error`,
                404: `Not Found Error`,
                422: `Validation Error`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * Read Unique Field Or 404
     * Returns a list of unique field values for a given set of search criteria for any collection
     *
     * __URL pattern:: /read/unique/{collection_name_path_param}?field=name__
     *
     * Returns:
     * list[Any]: list of unique values for a given field for a given collection(table)
     * @param collection
     * @param unique the db field name to get unique values for
     * @param id
     * @param managerId
     * @param documentId
     * @param utilityId
     * @param customerId
     * @param updatedDate
     * @param sqlId
     * @param invoiceCompanyName
     * @param createdDate
     * @param fileName
     * @param invoiceUrl
     * @param isValidated
     * @param commodity
     * @param facilityName
     * @param meterStartDate
     * @param meterEndDate
     * @param accountNumber
     * @param facilityType
     * @param question
     * @param entity
     * @param returnType
     * @param isActive
     * @param version
     * @param invoiceCategory
     * @param utilityType
     * @returns any Success
     * @throws ApiError
     */
    public invoiceReadUniqueFieldOr404(
        collection: CollectionNames,
        unique: string,
        id?: string,
        managerId?: string,
        documentId?: string,
        utilityId?: string,
        customerId?: string,
        updatedDate?: string,
        sqlId?: string,
        invoiceCompanyName?: string,
        createdDate?: string,
        fileName?: string,
        invoiceUrl?: string,
        isValidated?: boolean,
        commodity?: string,
        facilityName?: string,
        meterStartDate?: string,
        meterEndDate?: string,
        accountNumber?: string,
        facilityType?: string,
        question?: string,
        entity?: string,
        returnType?: string,
        isActive?: boolean,
        version?: number,
        invoiceCategory?: string,
        utilityType?: string
    ): CancelablePromise<Array<any>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v2/read/unique/{collection}',
            path: {
                collection: collection,
            },
            query: {
                unique: unique,
                _id: id,
                manager_id: managerId,
                document_id: documentId,
                utility_id: utilityId,
                customer_id: customerId,
                updated_date: updatedDate,
                sql_id: sqlId,
                invoice_company_name: invoiceCompanyName,
                created_date: createdDate,
                file_name: fileName,
                invoice_url: invoiceUrl,
                is_validated: isValidated,
                commodity: commodity,
                facility_name: facilityName,
                meter_start_date: meterStartDate,
                meter_end_date: meterEndDate,
                account_number: accountNumber,
                facility_type: facilityType,
                question: question,
                entity: entity,
                return_type: returnType,
                is_active: isActive,
                version: version,
                invoice_category: invoiceCategory,
                utility_type: utilityType,
            },
            errors: {
                400: `Bad Request Error`,
                404: `Not Found Error`,
                422: `Validation Error`,
                500: `Internal Server Error`,
            },
        });
    }
}
