/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { InvoiceCategoriesEnum } from '../models/InvoiceCategoriesEnum';
import type { InvoiceMapping } from '../models/InvoiceMapping';
import type { Prompt } from '../models/Prompt';
import type { PromptBase } from '../models/PromptBase';
import type { PromptOptional } from '../models/PromptOptional';
import type { PromptUpdate } from '../models/PromptUpdate';
import type { PymongoUpdateResult } from '../models/PymongoUpdateResult';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class PromptsPromptsService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * List Prompts Optional Or 404
     * @param id
     * @param utilityId
     * @param updatedDate
     * @param question
     * @param entity
     * @param returnType
     * @param isActive
     * @param version
     * @param invoiceCategory
     * @param utilityType
     * @param project Query param string with comma separated field names as they appear in database
     * @param skip
     * @param limit
     * @returns PromptOptional Success
     * @throws ApiError
     */
    public listPromptsOptionalOr404ApiV2ReadListPromptOptionalGet(
        id?: string,
        utilityId?: string,
        updatedDate?: string,
        question?: string,
        entity?: InvoiceMapping,
        returnType?: string,
        isActive?: boolean,
        version?: number,
        invoiceCategory?: InvoiceCategoriesEnum,
        utilityType?: string,
        project?: string,
        skip?: number,
        limit?: number
    ): CancelablePromise<Array<PromptOptional>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v2/read/list-prompt-optional',
            query: {
                _id: id,
                utility_id: utilityId,
                updated_date: updatedDate,
                question: question,
                entity: entity,
                return_type: returnType,
                is_active: isActive,
                version: version,
                invoice_category: invoiceCategory,
                utility_type: utilityType,
                project: project,
                skip: skip,
                limit: limit,
            },
            errors: {
                400: `Bad Request Error`,
                404: `Not Found Error`,
                422: `Validation Error`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * List Prompts Or 404
     * @param utilityId
     * @param question
     * @param entity
     * @param returnType
     * @param isActive
     * @param version
     * @param invoiceCategory
     * @returns Prompt Success
     * @throws ApiError
     */
    public listPromptsOr404ApiV2ReadListPromptGet(
        utilityId?: string,
        question?: string,
        entity?: InvoiceMapping,
        returnType?: string,
        isActive?: boolean,
        version?: number,
        invoiceCategory?: InvoiceCategoriesEnum
    ): CancelablePromise<Array<Prompt>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v2/read/list-prompt',
            query: {
                utility_id: utilityId,
                question: question,
                entity: entity,
                return_type: returnType,
                is_active: isActive,
                version: version,
                invoice_category: invoiceCategory,
            },
            errors: {
                400: `Bad Request Error`,
                404: `Not Found Error`,
                422: `Validation Error`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * Read Prompt Optional Or 404
     * @param id
     * @param utilityId
     * @param updatedDate
     * @param question
     * @param entity
     * @param returnType
     * @param isActive
     * @param version
     * @param invoiceCategory
     * @param utilityType
     * @param project Query param string with comma separated field names as they appear in database
     * @returns PromptOptional Success
     * @throws ApiError
     */
    public readPromptOptionalOr404ApiV2ReadPromptOptionalGet(
        id?: string,
        utilityId?: string,
        updatedDate?: string,
        question?: string,
        entity?: InvoiceMapping,
        returnType?: string,
        isActive?: boolean,
        version?: number,
        invoiceCategory?: InvoiceCategoriesEnum,
        utilityType?: string,
        project?: string
    ): CancelablePromise<PromptOptional> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v2/read/prompt-optional',
            query: {
                _id: id,
                utility_id: utilityId,
                updated_date: updatedDate,
                question: question,
                entity: entity,
                return_type: returnType,
                is_active: isActive,
                version: version,
                invoice_category: invoiceCategory,
                utility_type: utilityType,
                project: project,
            },
            errors: {
                400: `Bad Request Error`,
                404: `Not Found Error`,
                422: `Validation Error`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * Read Prompt Or 404
     * @param utilityId
     * @param question
     * @param entity
     * @param returnType
     * @param isActive
     * @param version
     * @param invoiceCategory
     * @returns Prompt Success
     * @throws ApiError
     */
    public readPromptOr404ApiV2ReadPromptGet(
        utilityId?: string,
        question?: string,
        entity?: InvoiceMapping,
        returnType?: string,
        isActive?: boolean,
        version?: number,
        invoiceCategory?: InvoiceCategoriesEnum
    ): CancelablePromise<Prompt> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v2/read/prompt',
            query: {
                utility_id: utilityId,
                question: question,
                entity: entity,
                return_type: returnType,
                is_active: isActive,
                version: version,
                invoice_category: invoiceCategory,
            },
            errors: {
                400: `Bad Request Error`,
                404: `Not Found Error`,
                422: `Validation Error`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * Create new new prompt
     * Create a new prompt for a utility company.
     *
     * - **_id**: is created dynamically.
     * - Not required in post request.
     * @param requestBody
     * @returns Prompt Success
     * @throws ApiError
     */
    public createPromptOr404ApiV2CreatePromptPost(
        requestBody: PromptBase
    ): CancelablePromise<Prompt> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v2/create/prompt',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request Error`,
                404: `Not Found Error`,
                422: `Validation Error`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * Update Prompt Or 404
     * @param id
     * @param requestBody
     * @returns Prompt Success
     * @throws ApiError
     */
    public updatePromptOr404ApiV2UpdatePromptIdPatch(
        id: string,
        requestBody: PromptUpdate
    ): CancelablePromise<Prompt> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/api/v2/update/prompt/{id}',
            path: {
                id: id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request Error`,
                404: `Not Found Error`,
                422: `Validation Error`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * Update Many Prompts
     * Bulk update many prompts according to
     * filter criteria of the Query parameters.
     *
     * __NOTE:__
     * - Error will be raised if no query params are included in URL.
     * @param requestBody
     * @param utilityId
     * @param question
     * @param entity
     * @param returnType
     * @param isActive
     * @param version
     * @param invoiceCategory
     * @returns PymongoUpdateResult Success
     * @throws ApiError
     */
    public updateManyPromptsApiV2UpdateManyPromptsPatch(
        requestBody: PromptUpdate,
        utilityId?: string,
        question?: string,
        entity?: InvoiceMapping,
        returnType?: string,
        isActive?: boolean,
        version?: number,
        invoiceCategory?: InvoiceCategoriesEnum
    ): CancelablePromise<PymongoUpdateResult> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/api/v2/update-many-prompts',
            query: {
                utility_id: utilityId,
                question: question,
                entity: entity,
                return_type: returnType,
                is_active: isActive,
                version: version,
                invoice_category: invoiceCategory,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request Error`,
                404: `Not Found Error`,
                422: `Validation Error`,
                500: `Internal Server Error`,
            },
        });
    }
}
