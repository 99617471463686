import React, { useState, useRef, useEffect } from 'react';
import {
    Dialog,
    DialogContent,
    Typography,
    TextField,
    Box,
    Divider,
    Grid,
    IconButton,
    Tooltip,
    Stack,
} from '@mui/material';

import SendIcon from '@mui/icons-material/Send';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import { PdfViewer } from 'src/modules/components';
import { useAuthenticator } from '@aws-amplify/ui-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faFilePdf,
    faFileExcel,
    faFileImage,
    faXmarkCircle,
    faFile,
} from '@fortawesome/free-regular-svg-icons';
import { Toast } from 'src/modules/components';

//api
import {
    useQueryAlerts,
    useMutationSendMessageAlert,
} from 'src/modules/hooks/apis/sql/alerts';
import { postAlertsPageSendMessage } from 'src/modules/generated-api-clients/sql';
import { useApiSqlContext } from 'src/modules/contexts/ApiSqlContext';

interface CommunicationDialogProps {
    open: boolean;
    onClose: () => void;
    alert_id?: string;
}

const CommunicationDialog: React.FC<CommunicationDialogProps> = props => {
    const { open, onClose, alert_id } = props;
    const { user } = useAuthenticator(context => [context.user]);
    const [reply, setReply] = useState('');
    const [attachedFiles, setAttachedFiles] = useState<File[]>([]);
    const fileInputRef = useRef<HTMLInputElement | null>(null);
    const [remainingCharacters, setRemainingCharacters] = useState(200);
    const maxCharacterLimit = 200;
    const { data: alertsData, isLoading, isError, error } = useQueryAlerts();

    useEffect(() => {
        if (isError) {
            console.log('error', error);
        }
    }, [isError, error]);

    // ==================================== TOAST ====================================
    const [toastOpen, setToastOpen] = useState(false);
    const [toastMessage, setToastMessage] = useState('');
    const handleToastClose = () => {
        setToastOpen(false);
    };

    // ==================================== DOWNLOAD ATTACHMENT ====================================
    const { client } = useApiSqlContext();

    const handleDownload = async (fileUrl: string) => {
        try {
            if (!client) {
                throw new Error('API client is not initialized');
            }

            const token = user
                .getSignInUserSession()
                ?.getIdToken()
                .getJwtToken();
            if (!token) {
                throw new Error('AWS Amplify user token cannot be undefined');
            }

            // Fetch the file data from the backend
            const downloadResponse =
                await client.default.getDownloadS3BucketFile(token, fileUrl);
            const fileContent: string =
                downloadResponse.body.file_bytes_base_64;

            // Decode the Base64 string to a byte array
            const byteCharacters = atob(fileContent);
            const byteNumbers = new Array(byteCharacters.length);
            for (let i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);

            // Create a Blob from the byte array
            const blob = new Blob([byteArray], {
                type: 'application/octet-stream',
            });

            // Create a URL from the Blob
            const fileBlobUrl = URL.createObjectURL(blob);

            // Download the file
            const link = document.createElement('a');
            link.href = fileBlobUrl;
            link.download = fileUrl.split('/').pop() || 'file';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);

            // Revoke the URL after download to free up memory
            URL.revokeObjectURL(fileBlobUrl);
        } catch (error) {
            console.error('Error downloading the file:', error);
        }
    };

    const alertMessages =
        alertsData?.body.find(alert => alert.id === alert_id)?.messages_json ||
        [];

    const handleReplyChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newText = event.target.value;
        setReply(newText);

        const remainingCharacters = maxCharacterLimit - newText.length;
        setRemainingCharacters(remainingCharacters);
    };

    const convertFileToBase64 = (file: File): Promise<string> => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () =>
                resolve((reader.result as string).split(',')[1]);
            reader.onerror = error => reject(error);
        });
    };

    const { mutate: sendMessageAlert } = useMutationSendMessageAlert();

    const handleReplySubmit = async () => {
        if (reply.trim()) {
            try {
                const convertedFiles = await Promise.all(
                    attachedFiles.map(async file => ({
                        file_name: file.name,
                        file_data: await convertFileToBase64(file),
                    }))
                );

                const requestBody: postAlertsPageSendMessage = {
                    alert_id: alert_id ?? '',
                    message_text: reply.trim(),
                    attached_files: convertedFiles,
                };

                sendMessageAlert(requestBody, {
                    onSuccess: () => {
                        console.log('Message sent successfully!');
                        setReply('');
                        setAttachedFiles([]);
                        setRemainingCharacters(maxCharacterLimit);
                    },
                });
            } catch (error) {
                console.error('Error preparing message submission:', error);
            }
        }
    };

    // ==================================== FILE ATTACHMENT ====================================

    const handleFileInputChange = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        if (event.target.files) {
            const files = Array.from(event.target.files);

            // Allowed extensions
            const allowedExtensions = [
                '.pdf',
                '.jpg',
                '.jpeg',
                '.png',
                '.gif',
                '.xlsx',
                '.xls',
                '.csv',
            ];

            // Maximum file size in bytes (20 MB)
            const maxFileSize = 20 * 1024 * 1024;

            const validFiles: File[] = [];
            const invalidFiles: string[] = [];

            files.forEach(file => {
                const fileExtension = file.name
                    .substring(file.name.lastIndexOf('.'))
                    .toLowerCase();

                if (
                    allowedExtensions.includes(fileExtension) &&
                    file.size <= maxFileSize
                ) {
                    validFiles.push(file);
                } else {
                    invalidFiles.push(file.name);
                }
            });

            // Add valid files to the state
            setAttachedFiles(prevFiles => [...prevFiles, ...validFiles]);

            // Show toast for invalid files
            if (invalidFiles.length > 0) {
                const invalidFileNames = invalidFiles.join(', ');
                const reason = files.some(file => file.size > maxFileSize)
                    ? 'Exceeded the maximum size of 20MB'
                    : 'Unsupported file type';

                setToastMessage(`Error: ${reason}: ${invalidFileNames}`);
                setToastOpen(true);
            }

            // Clear the input value to allow re-selecting the same file if needed
            if (fileInputRef.current) {
                fileInputRef.current.value = '';
            }
        }
    };

    const handleDeleteFile = (index: number) => {
        const updatedFiles = [...attachedFiles];
        updatedFiles.splice(index, 1);
        setAttachedFiles(updatedFiles);
    };

    const formatDate = (timestamp: any) => {
        const date = new Date(timestamp);
        return new Intl.DateTimeFormat('en-US', {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric',
            hour12: true,
        }).format(date);
    };

    return (
        <Dialog open={open} maxWidth="xl" fullWidth onClose={onClose}>
            <Toast
                id="fileUploadToast"
                isOpen={toastOpen}
                message={toastMessage}
                severity="error"
                onClose={handleToastClose}
            />
            <DialogContent>
                <Grid container spacing={2}>
                    <Grid item xs={8}>
                        <PdfViewer invoiceUrl="" />
                    </Grid>
                    <Grid item xs={4}>
                        <Typography id="dialogTitle" variant="h4" gutterBottom>
                            Communication History
                        </Typography>
                        <Divider sx={{ pb: 1 }} />
                        <Box
                            sx={{
                                height: '570px',
                                overflowY: 'auto',
                                py: 2,
                            }}
                        >
                            {alertMessages.length > 0 ? (
                                alertMessages.map((message, index) => (
                                    <Box key={index}>
                                        {message.user_email !==
                                        user.attributes?.email ? (
                                            <Box sx={{ py: 2 }}>
                                                <Grid container spacing={2}>
                                                    <Grid item xs={9}>
                                                        <Box
                                                            sx={{
                                                                border: '1px solid #1D4A74',
                                                                borderRadius:
                                                                    '12px',
                                                                p: 2,
                                                            }}
                                                        >
                                                            <Typography
                                                                id="messageReceiverName"
                                                                variant="h5"
                                                                sx={{ mb: 1 }}
                                                            >
                                                                {
                                                                    message.user_first_name
                                                                }{' '}
                                                                {
                                                                    message.user_last_name
                                                                }
                                                            </Typography>
                                                            <Typography id="messageReceivedContent">
                                                                {
                                                                    message.message_text
                                                                }
                                                            </Typography>

                                                            {/* WHEN ATTACHMENT BECOMES AVAILABLE DISPLAY THIS */}
                                                            {message
                                                                .attached_files
                                                                .length > 0 && (
                                                                <Box
                                                                    sx={{
                                                                        display:
                                                                            'flex', // Use flexbox
                                                                        flexWrap:
                                                                            'wrap', // Allow wrapping to the next line if there are too many files
                                                                        gap: 2, // Add spacing between the items
                                                                        mt: 2, // Add some margin from the top
                                                                    }}
                                                                >
                                                                    {message.attached_files.map(
                                                                        (
                                                                            fileUrl,
                                                                            index
                                                                        ) => {
                                                                            // Extract the filename from the URL
                                                                            const filename =
                                                                                fileUrl
                                                                                    .split(
                                                                                        '/alerts_messages_uploads/'
                                                                                    )[1]
                                                                                    ?.split(
                                                                                        '/'
                                                                                    )
                                                                                    ?.pop();

                                                                            // Determine the file extension, ensuring it is a valid string
                                                                            const fileExtension =
                                                                                filename
                                                                                    ?.split(
                                                                                        '.'
                                                                                    )
                                                                                    .pop()
                                                                                    ?.toLowerCase() ||
                                                                                '';

                                                                            // Choose the correct FontAwesome icon and color based on file type
                                                                            let fileIcon;
                                                                            let iconColor;

                                                                            if (
                                                                                [
                                                                                    'pdf',
                                                                                ].includes(
                                                                                    fileExtension
                                                                                )
                                                                            ) {
                                                                                fileIcon =
                                                                                    faFilePdf;
                                                                                iconColor =
                                                                                    '#C8102F'; // Red for PDFs
                                                                            } else if (
                                                                                [
                                                                                    'xls',
                                                                                    'xlsx',
                                                                                    'csv',
                                                                                ].includes(
                                                                                    fileExtension
                                                                                )
                                                                            ) {
                                                                                fileIcon =
                                                                                    faFileExcel;
                                                                                iconColor =
                                                                                    '#98BB4C'; // Green for Excel and CSV files
                                                                            } else if (
                                                                                [
                                                                                    'jpg',
                                                                                    'jpeg',
                                                                                    'png',
                                                                                    'gif',
                                                                                ].includes(
                                                                                    fileExtension
                                                                                )
                                                                            ) {
                                                                                fileIcon =
                                                                                    faFileImage;
                                                                                iconColor =
                                                                                    '#1D4A74'; // Blue for images
                                                                            } else {
                                                                                fileIcon =
                                                                                    faFile; // Default icon
                                                                                iconColor =
                                                                                    '#6C757D'; // Gray for unknown file types
                                                                            }

                                                                            // Shorten the filename for display if it's too long
                                                                            const maxDisplayLength = 8; // Maximum visible characters before truncating
                                                                            const shortFilename =
                                                                                filename &&
                                                                                filename.length >
                                                                                    maxDisplayLength
                                                                                    ? `...${filename.slice(-maxDisplayLength)}`
                                                                                    : filename; // Use the full name if it's short enough

                                                                            return (
                                                                                <Box
                                                                                    sx={{
                                                                                        borderRadius:
                                                                                            '12px',
                                                                                        border: '2px solid #4D4D4D',
                                                                                        p: 1,
                                                                                        cursor: 'pointer',
                                                                                        bgcolor:
                                                                                            '#F2F2F2',
                                                                                        width: '100px',
                                                                                        textAlign:
                                                                                            'center',
                                                                                    }}
                                                                                    onClick={() =>
                                                                                        handleDownload(
                                                                                            fileUrl
                                                                                        )
                                                                                    }
                                                                                >
                                                                                    <Stack>
                                                                                        <FontAwesomeIcon
                                                                                            icon={
                                                                                                fileIcon
                                                                                            }
                                                                                            size="2x"
                                                                                            style={{
                                                                                                color: iconColor,
                                                                                                marginBottom:
                                                                                                    '8px',
                                                                                            }}
                                                                                        />
                                                                                        <Typography
                                                                                            variant="body2"
                                                                                            sx={{
                                                                                                overflow:
                                                                                                    'hidden',
                                                                                                textOverflow:
                                                                                                    'ellipsis',
                                                                                                whiteSpace:
                                                                                                    'nowrap',
                                                                                                maxWidth:
                                                                                                    '100%',
                                                                                            }}
                                                                                            title={
                                                                                                filename
                                                                                            } // Show full name on hover
                                                                                        >
                                                                                            {shortFilename ||
                                                                                                'Download File'}
                                                                                        </Typography>
                                                                                    </Stack>
                                                                                </Box>
                                                                            );
                                                                        }
                                                                    )}
                                                                </Box>
                                                            )}
                                                        </Box>

                                                        <Box sx={{ pl: 1 }}>
                                                            <Typography
                                                                id="messageReceiverTimestamp"
                                                                variant="subtitle2"
                                                            >
                                                                {formatDate(
                                                                    message.time_stamp
                                                                )}
                                                            </Typography>
                                                        </Box>
                                                    </Grid>
                                                </Grid>
                                            </Box>
                                        ) : (
                                            <Box sx={{ py: 2 }}>
                                                <Grid container spacing={2}>
                                                    <Grid item xs={3}></Grid>
                                                    <Grid item xs={9}>
                                                        <Box
                                                            sx={{
                                                                bgcolor:
                                                                    '#1D4A74',
                                                                borderRadius:
                                                                    '12px',
                                                                p: 2,
                                                            }}
                                                        >
                                                            <Typography
                                                                id="messageSenderName"
                                                                variant="h5"
                                                                color="white"
                                                                sx={{ mb: 1 }}
                                                            >
                                                                {
                                                                    message.user_first_name
                                                                }{' '}
                                                                {
                                                                    message.user_last_name
                                                                }
                                                            </Typography>
                                                            <Typography
                                                                id="messageSenderContent"
                                                                color="white"
                                                            >
                                                                {
                                                                    message.message_text
                                                                }
                                                            </Typography>

                                                            {message
                                                                .attached_files
                                                                .length > 0 && (
                                                                <Box
                                                                    sx={{
                                                                        display:
                                                                            'flex', // Use flexbox
                                                                        flexWrap:
                                                                            'wrap', // Allow wrapping to the next line if there are too many files
                                                                        gap: 2, // Add spacing between the items
                                                                        mt: 2, // Add some margin from the top
                                                                    }}
                                                                >
                                                                    {message.attached_files.map(
                                                                        (
                                                                            fileUrl,
                                                                            index
                                                                        ) => {
                                                                            // Extract the filename from the URL
                                                                            const filename =
                                                                                fileUrl
                                                                                    .split(
                                                                                        '/alerts_messages_uploads/'
                                                                                    )[1]
                                                                                    ?.split(
                                                                                        '/'
                                                                                    )
                                                                                    ?.pop();

                                                                            // Determine the file extension, ensuring it is a valid string
                                                                            const fileExtension =
                                                                                filename
                                                                                    ?.split(
                                                                                        '.'
                                                                                    )
                                                                                    .pop()
                                                                                    ?.toLowerCase() ||
                                                                                '';

                                                                            // Choose the correct FontAwesome icon and color based on file type
                                                                            let fileIcon;
                                                                            let iconColor;

                                                                            if (
                                                                                [
                                                                                    'pdf',
                                                                                ].includes(
                                                                                    fileExtension
                                                                                )
                                                                            ) {
                                                                                fileIcon =
                                                                                    faFilePdf;
                                                                                iconColor =
                                                                                    '#C8102F'; // Red for PDFs
                                                                            } else if (
                                                                                [
                                                                                    'xls',
                                                                                    'xlsx',
                                                                                    'csv',
                                                                                ].includes(
                                                                                    fileExtension
                                                                                )
                                                                            ) {
                                                                                fileIcon =
                                                                                    faFileExcel;
                                                                                iconColor =
                                                                                    '#98BB4C'; // Green for Excel and CSV files
                                                                            } else if (
                                                                                [
                                                                                    'jpg',
                                                                                    'jpeg',
                                                                                    'png',
                                                                                    'gif',
                                                                                ].includes(
                                                                                    fileExtension
                                                                                )
                                                                            ) {
                                                                                fileIcon =
                                                                                    faFileImage;
                                                                                iconColor =
                                                                                    '#1D4A74'; // Blue for images
                                                                            } else {
                                                                                fileIcon =
                                                                                    faFile; // Default icon
                                                                                iconColor =
                                                                                    '#6C757D'; // Gray for unknown file types
                                                                            }

                                                                            // Shorten the filename for display if it's too long
                                                                            const maxDisplayLength = 8; // Maximum visible characters before truncating
                                                                            const shortFilename =
                                                                                filename &&
                                                                                filename.length >
                                                                                    maxDisplayLength
                                                                                    ? `...${filename.slice(-maxDisplayLength)}`
                                                                                    : filename; // Use the full name if it's short enough

                                                                            return (
                                                                                <Box
                                                                                    sx={{
                                                                                        borderRadius:
                                                                                            '12px',
                                                                                        border: '2px solid #4D4D4D',
                                                                                        p: 1,
                                                                                        cursor: 'pointer',
                                                                                        bgcolor:
                                                                                            '#F2F2F2',
                                                                                        width: '100px',
                                                                                        textAlign:
                                                                                            'center',
                                                                                    }}
                                                                                    onClick={() =>
                                                                                        handleDownload(
                                                                                            fileUrl
                                                                                        )
                                                                                    }
                                                                                >
                                                                                    <Stack>
                                                                                        <FontAwesomeIcon
                                                                                            icon={
                                                                                                fileIcon
                                                                                            }
                                                                                            size="2x"
                                                                                            style={{
                                                                                                color: iconColor,
                                                                                                marginBottom:
                                                                                                    '8px',
                                                                                            }}
                                                                                        />
                                                                                        <Typography
                                                                                            variant="body2"
                                                                                            sx={{
                                                                                                overflow:
                                                                                                    'hidden',
                                                                                                textOverflow:
                                                                                                    'ellipsis',
                                                                                                whiteSpace:
                                                                                                    'nowrap',
                                                                                                maxWidth:
                                                                                                    '100%',
                                                                                            }}
                                                                                            title={
                                                                                                filename
                                                                                            } // Show full name on hover
                                                                                        >
                                                                                            {shortFilename ||
                                                                                                'Download File'}
                                                                                        </Typography>
                                                                                    </Stack>
                                                                                </Box>
                                                                            );
                                                                        }
                                                                    )}
                                                                </Box>
                                                            )}
                                                        </Box>

                                                        <Box textAlign="end">
                                                            <Typography
                                                                id="messageSenderTimestamp"
                                                                variant="subtitle2"
                                                            >
                                                                {formatDate(
                                                                    message.time_stamp
                                                                )}
                                                            </Typography>
                                                        </Box>
                                                    </Grid>
                                                </Grid>
                                            </Box>
                                        )}
                                    </Box>
                                ))
                            ) : (
                                <Typography
                                    variant="subtitle1"
                                    sx={{ mt: 2, textAlign: 'center' }}
                                >
                                    No communications to display.
                                </Typography>
                            )}
                        </Box>
                        <Divider />

                        <Box sx={{ mt: 2 }}>
                            {attachedFiles.map((file, index) => (
                                <Box
                                    key={index}
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        mb: 2,
                                    }}
                                >
                                    {/* Show image preview */}
                                    {file.type.startsWith('image/') && (
                                        <Box sx={{ mr: 1 }}>
                                            <FontAwesomeIcon
                                                icon={faFileImage}
                                                size="lg"
                                            />
                                        </Box>
                                    )}

                                    {/* Show PDF and Excel icons */}
                                    {file.type === 'application/pdf' && (
                                        <Box sx={{ mr: 1, color: 'error' }}>
                                            <FontAwesomeIcon
                                                icon={faFilePdf}
                                                size="lg"
                                            />
                                        </Box>
                                    )}
                                    {(file.type ===
                                        'application/vnd.ms-excel' ||
                                        file.type ===
                                            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
                                        file.type === 'text/csv') && (
                                        <Box sx={{ mr: 1 }}>
                                            <FontAwesomeIcon
                                                icon={faFileExcel}
                                                size="lg"
                                            />
                                        </Box>
                                    )}

                                    {/* File name */}
                                    <Typography
                                        sx={{
                                            wordWrap: 'break-word', // Allow wrapping within words
                                            overflow: 'hidden', // Hide any overflow
                                            textOverflow: 'ellipsis', // Add ellipsis if necessary
                                            maxWidth: '100%', // Adjust to fit container
                                        }}
                                    >
                                        {file.name}
                                    </Typography>

                                    {/* Delete file button */}
                                    <IconButton
                                        onClick={() => handleDeleteFile(index)}
                                        color="inherit"
                                        size="small"
                                        // sx={{ ml: 1 }}
                                    >
                                        <FontAwesomeIcon icon={faXmarkCircle} />
                                        {/* <DeleteIcon /> */}
                                    </IconButton>
                                </Box>
                            ))}

                            <TextField
                                id="replyTextField"
                                label="Message"
                                multiline
                                fullWidth
                                rows={3}
                                variant="outlined"
                                value={reply}
                                onChange={handleReplyChange}
                                inputProps={{ maxLength: maxCharacterLimit }}
                                sx={{ mt: 2 }}
                            />
                            <Typography
                                id="remainingCharactersInfo"
                                variant="caption"
                            >
                                You have {remainingCharacters} characters
                                remaining
                            </Typography>

                            <Box
                                display="flex"
                                justifyContent="end"
                                sx={{ mt: 2 }}
                            >
                                <Tooltip title="Reply">
                                    <IconButton
                                        id="sendButton"
                                        color="primary"
                                        onClick={handleReplySubmit}
                                    >
                                        <SendIcon />
                                    </IconButton>
                                </Tooltip>
                                <Tooltip title="Attachment">
                                    <IconButton
                                        id="attachButton"
                                        onClick={() =>
                                            fileInputRef.current?.click()
                                        }
                                    >
                                        <AttachFileIcon />
                                        <input
                                            id="fileInput"
                                            type="file"
                                            ref={fileInputRef}
                                            style={{ display: 'none' }}
                                            onChange={handleFileInputChange}
                                            multiple
                                        />
                                    </IconButton>
                                </Tooltip>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    );
};

export default CommunicationDialog;
